<template>
  <div class="left col-lg-2 advertisement"></div>
  <div class="right col-lg-2 advertisement"></div>
  <router-view />
  <div class="row">
    <div class="bottom col-xs-12 advertisement"></div>
  </div>
</template>

<style>
#app {
  font-family: "Roboto Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
  max-width: 100vw;
  padding: none;
  margin: none;
  overflow-y: scroll;
  color: #161c2d;
}

.left,
.right {
  height: 100vh;
  top: 0;
  position: fixed;
}

.left {
  float: left;
  left: 0;
}
.right {
  float: right;
  right: 0;
}

.bottom {
  height: 60vh;
}

.advertisement {
  background-color: rgb(204, 204, 204);
}

*::selection {
  background: #161c2d;
  color: white;
}

*::-moz-selection {
  background: #161c2d;
  color: white;
}

@media screen and (max-width: 991px) {
  .left,
  .right {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .bottom {
    display: none;
  }
}
</style>
