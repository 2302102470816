/* eslint-disable */
<template>
  <div class="home container">
    <div class="row p-5 main-page">
      <div class="p-5 title">
        <h1>random number generator</h1>
      </div>
      <div class="number-display">
        <p class="big-number">{{ tweened.toFixed(0) }}</p>
        <!---
        <VDropdown :triggers="[]" :shown="copyComplete" :autoHide="false">
          <button
            class="btn"
            v-clipboard="number"
            @click="
              () => {
                this.copyComplete = true;
                setTimeout(() => {
                  this.copyComplete = false;
                }, 2500);
              }
            "
          >
            <img
              class="copy-icon"
              :src="require(`@/assets/copy.svg`)"
              alt=""
            />
          </button>
          
          <template #popper>copied to clipboard</template>
        </VDropdown>
        -->
        <button
          v-if="paused == false"
          class="play-pause-btn"
          @click="paused = true"
        >
          <img
            class="paused-icon"
            :src="require(`@/assets/pause.svg`)"
            alt=""
          />
        </button>
        <button v-else class="play-pause-btn" @click="paused = false">
          <img class="paused-icon" :src="require(`@/assets/play.svg`)" alt="" />
        </button>
        <button class="btn" v-clipboard="number">
          <img class="copy-icon" :src="require(`@/assets/copy.svg`)" alt="" />
        </button>
        <div class="countdown">
          {{ countdown }}
        </div>
        <div class="row nice" v-if="number == 69">
          <h2>nice</h2>
        </div>
      </div>
      <FormKit type="form" @keydown.enter="handleEnter">
        <div class="row formkit-row">
          <h4>Minimum</h4>
          <div class="col-md-9">
            <input
              v-model="this.minInt"
              type="range"
              min="0"
              :max="this.maxInt"
              class="slider"
            />
            <!--
            <FormKit
              class="slider-pink"
              v-model="this.minInt"
              type="range"
              name="min"
              min="0"
              :max="this.maxInt"
              step="1"
            />
            -->
          </div>
          <div class="col-md-3">
            <input
              class="input-pink"
              v-model="this.minInt"
              type="number"
              min="0"
              :max="this.maxInt"
            />
            <!--
            <FormKit
              v-model="this.minInt"
              type="number"
              name="min"
              :value="this.minInt"
              step="1"
            />
            -->
          </div>
        </div>
        <div class="row formkit-row">
          <h4>Maximum</h4>
          <div class="col-md-9">
            <input
              v-model="this.maxInt"
              type="range"
              :min="this.minInt"
              :max="this.maxIntBoundary"
              class="slider"
            />
            <!--
            <FormKit
              class="slider-pink"
              v-model="this.maxInt"
              type="range"
              name="max"
              :min="this.minInt"
              :max="this.maxIntBoundary"
              step="1"
            />
            -->
          </div>
          <div class="col-md-3">
            <input
              class="input-pink"
              v-model="this.maxInt"
              type="number"
              :min="this.minInt"
              :max="this.maxIntBoundary"
            />
            <!--
            <FormKit
              v-model="this.maxInt"
              type="number"
              name="max"
              :value="this.maxInt"
              :min="this.minInt"
              :max="this.maxIntBoundary"
              step="1"
            />
            -->
          </div>
        </div>
      </FormKit>
    </div>
  </div>
  <FooterComponent :number="this.number"></FooterComponent>
</template>

<script>
import FooterComponent from "@/components/FooterComponent.vue";

import gsap from "gsap";
import "@formkit/themes/genesis";

//import Slider from "@vueform/slider";
import "floating-vue/dist/style.css";

export default {
  name: "HomeView",
  components: {
    FooterComponent,
    //Slider,
  },

  props: {},

  watch: {
    number() {
      gsap.to(this, { duration: 0.5, tweened: Number(this.number) || 0 });
    },
    minInt() {
      //this.verifyMinInt();
    },
    maxInt() {
      //this.verifyMaxInt();
    },
  },

  data() {
    return {
      number: null,
      tweened: 0,

      paused: false,

      minInt: 0,
      maxInt: 5000,
      maxIntBoundary: 10000000,

      countdown: 5,

      copyComplete: false,
    };
  },

  beforeMount() {
    this.number = this.getRandomInt(this.minInt, this.maxInt);
  },

  mounted() {
    setInterval(() => {
      this.countdownTick();
    }, 1000);
    /*
    setInterval(() => {
      this.verifyMinInt();
      this.verifyMaxInt();
      this.countdown = 5;
      this.number = this.getRandomInt(this.minInt, this.maxInt);
    }, 5000);
    */
  },

  computed: {},

  methods: {
    countdownTick() {
      if (!this.paused) {
        if (this.countdown <= 0) {
          this.verifyMinInt();
          this.verifyMaxInt();
          this.number = this.getRandomInt(this.minInt, this.maxInt);
          this.countdown = 5;
        } else {
          this.countdown = this.countdown - 1;
        }
      }
    },

    getRandomInt(min, max) {
      // eslint-disable-next-line
      return parseInt(Math.floor(Math.random() * (max - min + 1))) + parseInt(min);
    },

    handleEnter(e) {
      e.preventDefault();
      this.verifyMinInt();
      this.verifyMaxInt();
      e.target.blur();
    },

    verifyMinInt() {
      if (
        this.minInt == undefined ||
        this.minInt == null ||
        this.minInt == "" ||
        this.minInt < 0 ||
        Number.isNaN(this.minInt)
      ) {
        this.minInt = 0;
      }
      if (this.minInt > this.maxInt) {
        this.minInt = parseInt(this.maxInt);
      }
      this.minInt = parseInt(this.minInt);
    },
    verifyMaxInt() {
      if (
        this.maxInt == undefined ||
        this.maxInt == null ||
        this.maxInt == "" ||
        Number.isNaN(this.maxInt)
      ) {
        this.maxInt = this.maxIntBoundary;
      }
      if (this.maxInt < this.minInt || this.maxInt < 0) {
        this.maxInt = parseInt(this.minInt);
        this.minInt = 0;
      }
      if (this.maxInt > this.maxIntBoundary) {
        this.maxInt = this.maxIntBoundary;
      }
      this.maxInt = parseInt(this.maxInt);
    },
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.home {
  height: inherit;
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.main-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.big-number {
  min-height: 25vh;
  margin: 15px;
}

.title {
  font-size: 2.5em;
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.number-display {
  background-color: rgb(235, 235, 235);
  border: 2px solid #161c2d;
  position: relative;
}

.big-number {
  font-weight: normal;
  font-size: 3em;

  display: flex;
  justify-content: center;
  align-items: center;
}

.play-pause-btn {
  background-color: inherit;
  border: none;
  color: white;
  padding: 12px 16px 12px 16px;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  left: 16px;
}
.btn {
  background-color: inherit;
  border: none;
  color: white;
  padding: 12px 16px 12px 16px;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.play-pause-btn:hover,
.btn:hover {
  background-color: rgb(225, 225, 225);
}

.play-pause-btn:active,
.btn:active {
  padding: 13px 16px 11px 16px;
}

.countdown {
  background-color: inherit;
  border: none;
  color: #161c2d;
  padding: 12px 16px 12px 16px;
  font-size: 2em;
  cursor: pointer;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.nice {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}

FormKit {
  width: 100%;
}
.formkit-row {
  margin: 30px 0 30px 0;
  padding: 15px 0 15px 0;
}

:deep(.formkit-label),
:deep(.formkit-input) {
  font-family: "Roboto Mono", monospace;
}

:deep(.formkit-form) {
  text-emphasis: none;
}
:deep(.formkit-inner:focus) {
  border: 2px solid #161c2d;
}
:deep(.formkit-wrapper button) {
  visibility: hidden;
}

.slider-pink {
  margin-top: 25px;
  --slider-bg: rgb(235, 235, 235);
  /*--slider-handle-bg: pink;*/
  --slider-handle-bg: #161c2d;
  --slider-connect-bg: rgb(235, 235, 235);
  /*--slider-tooltip-bg: pink;*/
  --slider-tooltip-bg: #161c2d;
  --slider-handle-ring-color: #ef444430;
}

.input-pink {
  width: 100%;
  height: 45px;
  outline: none;
  border-radius: 5px;
  padding: 0 10px 0 10px;
  background-color: rgb(235, 235, 235);
  /*border: 2px solid pink;*/
  border: 2px solid #161c2d;
}

.slider {
  -webkit-appearance: none;
  width: 90%;
  float: left;
  margin-top: 15px;
  height: 5px;
  background: rgb(235, 235, 235);
  outline: none;
  /*opacity: 0.7;*/
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #161c2d;
  border-radius: 10px;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #161c2d;
  border-radius: 10px;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .home {
    width: 100vw;
  }
  .slider-pink {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .home {
    width: 100vw;
  }
  .slider {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 1em;
    margin: none;
  }
  .big-number {
    font-size: 1.5em;
  }
  .countdown {
    font-size: 1em;
  }
}
</style>
