<template>
  <div class="footer">
    <h1 hidden>{{ number }}</h1>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  props: {
    number: Number,
  },
};
</script>

<style scoped>
h1 {
  color: pink;
}
</style>
