import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import { VueClipboard } from "@soerenmartius/vue3-clipboard";

import FloatingVue from "floating-vue";

import { plugin, defaultConfig } from "@formkit/vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

createApp(App)
  .use(router)
  .use(VueClipboard)
  .use(FloatingVue)
  .use(plugin, defaultConfig)
  .mount("#app");
